<template>
  <div id="channels_preview">
    <el-dialog
      :title="previewConf.group_name + ' ' + previewConf.device_name"
      v-model="pageConf.show"
      destroy-on-close
      :width="1300"
      :before-close="closePage"
    >
      <div id="playWnd" class="preview-play"></div>
    </el-dialog>
  </div>
</template>

<script setup>
import { getCurrentInstance, reactive, defineExpose } from "vue";

// 访问 globalProperties
const ctx = getCurrentInstance().appContext.config.globalProperties;

// 弹出层配置文件
const pageConf = reactive({
  show: false,
});

// 视频配置参数
const previewConf = reactive({
  webControl: null,
  webSzPluginContainer: "playWnd",
  group_name: "",
  device_name: "",
  device_sn: "",
  channel_no: "",
  ezviz_account: {},
});

// 显示页面
const openPage = (cs_id) => {
  // 查询预览视频配置参数信息
  ctx.$request_
    .post(ctx.$api_.state.Carema.channels.preview.url, {
      cs_id: cs_id,
    })
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        previewConf.group_name = respon.Data.group_name;
        previewConf.device_name = respon.Data.device_name;
        previewConf.device_sn = respon.Data.device_sn;
        previewConf.channel_no = respon.Data.channel_no;
        previewConf.ezviz_account = respon.Data.ezviz_account;
        pageConf.show = true;

        WebControlInit();
      } else {
        ctx.$notify({
          title: "温馨提示",
          message: respon.Message,
          type: "warning",
        });
      }
    });
};

// 关闭页面
const closePage = () => {
  previewConf.webControl.JS_RequestInterface({
    funcName: "stopAllPreview",
  });
  previewConf.webControl.JS_HideWnd();
  previewConf.webControl.JS_Disconnect();
  previewConf.webControl = null;
  pageConf.show = false;
};

// 建立插件实例
const WebControlInit = () => {
  previewConf.webControl = new WebControl({
    szPluginContainer: previewConf.webSzPluginContainer,
    iServicePortStart: 14510,
    iServicePortEnd: 14519,
    cbConnectSuccess: () => {
      previewConf.webControl
        .JS_StartService("window", {
          dllPath: "./chain/cloudTransform.dll",
        })
        .then(() => {
          previewConf.webControl
            .JS_CreateWnd(previewConf.webSzPluginContainer, 1240, 680)
            .then(() => {});
        })
        .catch((error) => {
          previewConf.webControl = null;
          ctx.$notify({
            title: "温馨提示",
            message: "插件启动失败，请检查是否安装插件！",
            type: "error",
          });
        });
    },
    cbConnectError: () => {
      previewConf.webControl = null;
      ctx.$notify({
        title: "温馨提示",
        message: "插件启动失败，请检查是否安装插件！",
        type: "error",
      });
    },
    cbConnectClose: (bNormalClose) => {},
  });

  // 设置插件位置
  previewConf.webControl.oDocOffset.top = 5;
  previewConf.webControl.oDocOffset.left = 10;

  setTimeout(() => {
    // 初始化插件
    previewConf.webControl
      .JS_RequestInterface({
        funcName: "Init",
        arguments: encodeURI(
          JSON.stringify({
            layout: 1,
            iWndType: 0,
            response: {
              code: 0,
              message: null,
              data: {
                appKey: previewConf.ezviz_account.appKey,
                ezvizToken: previewConf.ezviz_account.token,
                videoLevel: 1,
                showMainTool: 0,
                showSubTool: 1,
                waterMark: ctx.$system_.state.login_user_name,
                userName: ctx.$system_.state.login_user_name,
              },
            },
          })
        ),
      })
      .then(function () {
        // 开启预览
        startPreview();
      });
  }, 1500);
};

// 开启预览
const startPreview = () => {
  previewConf.webControl
    .JS_RequestInterface({
      funcName: "StartPreview",
      arguments: encodeURI(
        JSON.stringify({
          response: {
            code: 0,
            message: null,
            data: {
              deviceSerial: previewConf.device_sn,
              channelNo: parseInt(previewConf.channel_no),
              codeIsEncrypt: 0,
              channelName: previewConf.device_name,
              storeName: previewConf.group_name,
            },
          },
        })
      ),
    })
    .then(function () {});
};

defineExpose({
  openPage,
});
</script>
<style lang="less">
#channels_preview {
  .el-dialog {
    margin-top: 3vh !important;

    .el-dialog__body {
      padding: 0 1.1vw;
      height: 39vw;

      .preview-play {
        margin: 20px 0;
        height: 36vw;
        background: rgba(0, 0, 0, 0.9);
      }
    }
  }
}
</style>
