<template>
	<div id="add_group">
		<el-dialog title="添加分组" v-model="pageConf.show" destroy-on-close :width="600">
			<el-form ref="formRef" class="common-dialog-form" :model="forms" :rules="rules" size="large">
				<el-form-item label="分组名称" prop="gr_name">
					<el-input v-model="forms.gr_name" maxlength="30" placeholder="请输入设备分组名称">
					</el-input>
				</el-form-item>
				<el-form-item label="分组编号" prop="gr_hik_no">
					<el-input v-model="forms.gr_hik_no" maxlength="50" placeholder="请输入设备分组编号">
					</el-input>
				</el-form-item>
				<el-form-item label="所属区域" prop="gr_bgid">
					<el-select v-model="forms.gr_bgid" clearable filterable placeholder="请选择设备分组所属区域">
						<el-option v-for="item in bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
							:value="item.bg_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分组排序" prop="gr_sort">
					<el-input type="number" v-model="forms.gr_sort" maxlength="5" placeholder="请输入设备分组排序 顺序"></el-input>
				</el-form-item>
				<el-form-item label="备注说明">
					<el-input type="textarea" v-model="forms.gr_remark" maxlength="200" placeholder="备注说明，不超过200个字符">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="common-custom-forms-btn" @click="submitForm()">立即添加
					</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		getCurrentInstance,
		ref,
		reactive,
		defineExpose,
		defineEmits
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 弹出层配置文件
	const pageConf = reactive({
		show: false
	})

	// 显示页面
	const openPage = () => {
		// 重置表单信息
		forms.gr_name = ''
		forms.gr_hik_no = ''
		forms.gr_bgid = ''
		forms.gr_sort = ''
		forms.gr_remark = ''

		pageConf.show = true

		if (bigarea.options.length <= 0) {
			// 初始化加载区域列表
			ctx.$pubFun_.getSelectCommonBigarea(bigarea)
		}
	}

	// 设置待抛出的方法名称
	const emit = defineEmits(['submit_suc'])

	// 公共区域列表
	const bigarea = reactive({
		options: []
	})

	// 表单数据
	const forms = reactive({
		gr_name: '',
		gr_hik_no: '',
		gr_bgid: '',
		gr_sort: '',
		gr_remark: ''
	})

	// 设置表单ref
	const formRef = ref()

	// 设置表单验证规则
	const rules = reactive({
		gr_name: [{
			required: true,
			message: '请输入分组名称',
			trigger: 'blur'
		}],
		gr_hik_no: [{
			required: true,
			message: '请输入分组编号',
			trigger: 'blur'
		}],
		gr_bgid: [{
			required: true,
			message: '请选择所属区域',
			trigger: 'change'
		}],
		gr_sort: [{
			required: true,
			message: '请输入分组排序',
			trigger: 'blur'
		}]
	})

	// 提交表单数据
	const submitForm = () => {
		formRef.value.validate((valid) => {
			if (valid === true) {
				ctx.$request_.post(ctx.$api_.state.Carema.group
					.add.url, forms).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})
						pageConf.show = false
						emit('submit_suc')
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}
		})
	}

	defineExpose({
		openPage
	})
</script>
<style lang="less">
	#add_group {
		.el-dialog {
			margin-top: 15vh !important;

			.el-dialog__body {
				padding: 0 1.1vw;
				height: 56vh;
			}
		}
	}
</style>
