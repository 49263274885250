<template>
	<div id="add_device">
		<el-dialog title="添加设备" v-model="pageConf.show" destroy-on-close :width="600">
			<el-form ref="formRef" class="common-dialog-form" :model="forms" :rules="rules" size="large">
				<el-form-item label="设备名称" prop="e_name">
					<el-input v-model="forms.e_name" maxlength="30" placeholder="请输入设备名称">
					</el-input>
				</el-form-item>
				<el-form-item label="序列号" prop="e_hik_no">
					<el-input v-model="forms.e_hik_no" maxlength="50" placeholder="请输入设备序列号">
					</el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="e_hik_code">
					<el-input v-model="forms.e_hik_code" maxlength="50" placeholder="请输入设备验证码，设备机身上的六位大写字母">
					</el-input>
				</el-form-item>
				<el-form-item label="设备排序" prop="e_sort">
					<el-input type="number" v-model="forms.e_sort" maxlength="5" placeholder="请输入设备排序 顺序"></el-input>
				</el-form-item>
				<el-form-item label="备注说明">
					<el-input type="textarea" v-model="forms.e_remark" maxlength="200" placeholder="备注说明，不超过200个字符">
					</el-input>
				</el-form-item>
				<el-form-item label="分组名称">
					<el-input v-model="forms.e_grname" disabled></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="common-custom-forms-btn" @click="submitForm()">立即添加
					</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		getCurrentInstance,
		ref,
		reactive,
		defineExpose,
		defineEmits
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 弹出层配置文件
	const pageConf = reactive({
		show: false
	})

	// 显示页面
	const openPage = (gr_id, gr_name) => {
		// 重置表单信息
		forms.e_grid = gr_id
		forms.e_grname = gr_name
		forms.e_name = ''
		forms.e_hik_no = ''
		forms.e_hik_code = ''
		forms.e_sort = ''
		forms.e_remark = ''

		pageConf.show = true
	}

	// 设置待抛出的方法名称
	const emit = defineEmits(['submit_suc'])

	// 表单数据
	const forms = reactive({
		e_grid: 0,
		e_grname: '',
		e_name: '',
		e_hik_no: '',
		e_hik_code: '',
		e_sort: '',
		e_remark: ''
	})

	// 设置表单ref
	const formRef = ref()

	// 设置表单验证规则
	const rules = reactive({
		e_name: [{
			required: true,
			message: '请输入设备名称',
			trigger: 'blur'
		}],
		e_hik_no: [{
			required: true,
			message: '请输入设备序列号',
			trigger: 'blur'
		}],
		e_hik_code: [{
			required: true,
			message: '请输入设备验证码',
			trigger: 'blur'
		}],
		e_sort: [{
			required: true,
			message: '请输入设备排序',
			trigger: 'blur'
		}]
	})

	// 提交表单数据
	const submitForm = () => {
		formRef.value.validate((valid) => {
			if (valid === true) {
				ctx.$request_.post(ctx.$api_.state.Carema.device
					.add.url, forms).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})
						pageConf.show = false
						emit('submit_suc')
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}
		})
	}

	defineExpose({
		openPage
	})
</script>
<style lang="less">
	#add_device {
		.el-dialog {
			margin-top: 15vh !important;

			.el-dialog__body {
				padding: 0 1.1vw;
				height: 56vh;
			}
		}
	}
</style>
