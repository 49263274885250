<template>
	<div id="camera">
		<div class="main-body-filter">
			<div class="main-body-filter-left">
				<el-select v-model="filter.bigarea.value" :loading="filter.bigarea.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部区域" @change="loadGroupFilterList">
					<el-option v-for="item in filter.bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
						:value="item.bg_id">
					</el-option>
				</el-select>
				<el-select v-model="filter.group.value" :loading="filter.group.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部分组" @change="selectFilter">
					<el-option v-for="item in filter.group.options" :key="item.gr_id" :label="item.gr_name"
						:value="item.gr_id">
					</el-option>
				</el-select>
				<el-select v-model="filter.status.value" class="filter-select" size="large" clearable filterable
					placeholder="全部状态" @change="selectFilter">
					<el-option v-for="item in filter.status.options" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-input class="search-keywords filter-input" size="large" placeholder="请输入分组名称或设备编号检索查询"
					prefix-icon="Search" v-model="filter.keywords" clearable @keyup.enter="inputFilter">
				</el-input>
			</div>
			<div class="main-body-filter-right">
				<el-badge :value="table.device_total" class="item" type="info">
					<el-button type="info" plain @click="badgeFilter(0,0,-1)" title="点击查询">全部设备</el-button>
				</el-badge>
				<el-badge :value="table.device_online" class="item" type="success">
					<el-button type="success" plain @click="badgeFilter(0,0,1)" title="点击查询">在线设备
					</el-button>
				</el-badge>
				<el-badge :value="table.device_offline" class="item" type="danger">
					<el-button type="danger" plain @click="badgeFilter(0,0,2)" title="点击查询">离线设备
					</el-button>
				</el-badge>
				<el-button type="primary" icon="Plus" @click="openAddGroupPage">
					添加分组
				</el-button>
			</div>
		</div>
		<div class="main-body-table">
			<el-table width="100%" height="68vh" max-height="68vh" :data="table.list" row-key="nid" border lazy
				size="large" :load="loadChild" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" :empty-text="table.empty_text" ref="table_ref">
				<el-table-column prop="nid" label="ID" width="200">
				</el-table-column>
				<el-table-column prop="title" label="分组/设备名称" width="400">
					<template #default="scope">
						<span v-if="scope.row.level === 3">{{scope.row.title}}</span>
						<span class="table-row-set-text" @click="setText(scope,'title','分组/设备名称')"
							v-else>{{scope.row.title}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hik_no" label="海康编号" width="400">
				</el-table-column>
				<el-table-column prop="hik_status" label="设备状态" width="100" align="center">
					<template #default="scope">
						<el-badge is-dot class="item" v-if="scope.row.level === 2 && scope.row.hik_status === 1"
							type="success">
						</el-badge>
						<el-badge is-dot class="item" v-if="scope.row.level === 2 && scope.row.hik_status === 2">
						</el-badge>
					</template>
				</el-table-column>
				<el-table-column prop="sort" label="排序" width="100" align="center">
					<template #default="scope">
						<span v-if="scope.row.level === 3">{{scope.row.sort}}</span>
						<span class="table-row-set-text" @click="setText(scope,'sort','排序','number')"
							v-else>{{scope.row.sort}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="remark" label="备注说明" width="300">
					<template #default="scope">
						<span v-if="scope.row.level === 3">{{scope.row.remark}}</span>
						<span class="table-row-set-text" @click="setText(scope,'remark','备注说明')"
							v-else>{{scope.row.remark ? scope.row.remark : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template #default="scope">
						<el-button-group v-if="scope.row.level === 1">
							<el-tooltip content="添加设备" placement="top">
								<el-icon @click="scope.row.hik_no ? openAddDevicePage(scope.row.param_id,scope.row.title) : ''" 
								:style="{'cursor' : (scope.row.hik_no ? 'pointer' : 'not-allowed'), 'color': (scope.row.hik_no ? '#409EFC' : '#A8ABB2'), 'border': '1px solid lightgray', 'margin-right': '8px', 'padding': '3px'}">
									<UploadFilled />
								</el-icon>
							</el-tooltip>
							<el-tooltip content="删除分组" placement="top">
								<el-icon @click="delGroup(scope.row.param_id)" 
									color="#f89898" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
									<DeleteFilled />
								</el-icon>
							</el-tooltip>
						</el-button-group>
						<el-button-group v-else-if="scope.row.level === 2">
							<el-tooltip content="同步设备下通道" placement="top">
								<el-icon @click="scope.row.hik_no && scope.row.hik_status === 1 ? synchChannels(scope.row) : ''" 
									:style="{'cursor' : (scope.row.hik_no && scope.row.hik_status === 1 ? 'pointer' : 'not-allowed'), 'color': (scope.row.hik_no && scope.row.hik_status === 1 ? '#409EFC' : '#A8ABB2'), 'border': '1px solid lightgray', 'margin-right': '8px', 'padding': '3px'}">
									<HelpFilled />
								</el-icon>
							</el-tooltip>
							<el-tooltip content="删除设备" placement="top">
								<el-icon @click="delDevice(scope.row.param_id)" 
									color="#f89898" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
									<DeleteFilled />
								</el-icon>
							</el-tooltip>
						</el-button-group>
						<el-button-group v-else-if="scope.row.level === 3">
							<el-tooltip content="视频预览" placement="top">
								<el-icon @click="openChannelsPerviewPage(scope.row.param_id)" 
									color="#409EFC" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
									<VideoCameraFilled />
								</el-icon>
							</el-tooltip>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="main-body-table-page" background layout="prev, pager, next, jumper, sizes, total"
				:total="table.total" :current-page="table.page" :page-size="table.size" :page-sizes="table.sizes"
				@size-change="sizeChange" @current-change="currentChange">
			</el-pagination>
		</div>
		<div class="dialog-page">
			<ChannelsPreview ref="channels_perview_ref"></ChannelsPreview>
			<AddGroup ref="add_group_ref" @submit_suc='loadList'></AddGroup>
			<AddDevice ref="add_device_ref" @submit_suc='loadList'></AddDevice>
		</div>
	</div>
</template>

<script setup>
	import ChannelsPreview from '@/components/carema/ChannelsPreview.vue'
	import AddGroup from '@/components/carema/AddGroup.vue'
	import AddDevice from '@/components/carema/AddDevice.vue'
	import {
		getCurrentInstance,
		ref,
		onMounted,
		reactive
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties
	// 顶部筛选
	const filter = reactive({
		keywords: '',
		bigarea: {
			options: [],
			value: '',
			is_load: false
		},
		group: {
			options: [],
			value: '',
			is_load: false
		},
		status: {
			options: [{
				label: "在线",
				value: 1
			}, {
				label: "离线",
				value: 2
			}],
			value: ''
		}
	});

	// 表单数据
	const table = reactive({
		list: [],
		loading: false,
		page: 1,
		size: 12,
		sizes: [12, 50, 100, 500],
		total: 0,
		device_total: 0,
		device_online: 0,
		device_offline: 0,
		empty_text: '暂无更多数据'
	})

	// 获取table列表
	const loadList = () => {
		// 清除现有table数据
		table.list = []
		table.loading = true
		table.empty_text = '数据查询中...'
		ctx.$request_nl_.post(ctx.$api_.state.Carema.group.list.url, {
				bg_id: filter.bigarea.value,
				gr_id: filter.group.value,
				status: filter.status.value,
				keywords: filter.keywords,
				page: table.page,
				limit: table.size
			})
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					table.total = respon.Data.total
					table.list = respon.Data.list
					table.device_total = respon.Data.device_total
					table.device_online = respon.Data.device_online
					table.device_offline = respon.Data.device_total - respon.Data.device_online
				} else {
					ctx.$message.error({
						message: res.Message
					});
					treeNode.loaded = true
					treeNode.loading = false
				}
				
				table.loading = false
				table.empty_text = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
			}).catch(error => {
				table.loading = false
				table.empty_text = "服务器连接失败，请稍后重试"
			})
	}

	// 获取table子节点
const loadChild = (tree, treeNode, resolve) => {
		if (tree.level === 1) {
			// 查询设备列表
			ctx.$request_nl_.post(ctx.$api_.state.Carema.device.list.url, {
					gr_id: tree.param_id,
					status: filter.status.value,
					keywords: filter.keywords
				})
				.then((respon) => {
					if (respon.Code === ctx.$code_.state.success) {
						resolve(respon.Data.list)
					} else {
						treeNode.loaded = false
						treeNode.loading = false

						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				}).catch(error => {
					treeNode.loaded = false
					treeNode.loading = false
				})
		} else if (tree.level === 2) {
			// 查询通道列表
			ctx.$request_nl_.post(ctx.$api_.state.Carema.channels.list.url, {
					e_id: tree.param_id
				})
				.then((respon) => {
					if (respon.Code === ctx.$code_.state.success) {
						resolve(respon.Data.list)
					} else {
						treeNode.loaded = false
						treeNode.loading = false

						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				}).catch(error => {
					treeNode.loaded = false
					treeNode.loading = false
				})
		}
	}

	// 区域选中，更改设备分组列表数据
	const loadGroupFilterList = (bg_id) => {
		filter.group.options = []
		filter.group.value = ''
		ctx.$pubFun_.getSelectCommonGroup(filter.group, bg_id)
		selectFilter()
	}

	// 筛选框调用事件
	const selectFilter = () => {
		filter.keywords = ''
		table.page = 1
		loadList()
	}

	// 输入框调用事件
	const inputFilter = () => {
		// 清空select选项框
		filter.bigarea.value = ''
		filter.group.options = []
		filter.group.value = ''
		ctx.$pubFun_.getSelectCommonGroup(filter.group, 0)
		table.page = 1
		loadList()
	}

	// bage图标状态切换事件
	const badgeFilter = (bg_id = 0, group = 0, status = 0) => {
		if (bg_id > 0) {
			filter.bigarea.value = bg_id
		}
		if (group > 0) {
			filter.group.value = group
		}
		if (status > 0) {
			filter.status.value = status
		} else if (status === -1) {
			filter.status.value = ''
		}
		selectFilter()
	}

	// 删除设备分组
	const delGroup = (gr_id) => {
		ctx.$confirm('您确认要删除该分组吗', '温馨提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				ctx.$request_.post(ctx.$api_.state.Carema.group
					.del.url, {
						gr_id: gr_id
					}).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})

						loadList()
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}).catch(() => {
				ctx.$notify({
					title: '温馨提示',
					message: '已取消',
					type: 'info'
				})
			})
	}

	// 删除设备
	const delDevice = (e_id) => {
		ctx.$confirm('您确认要删除该设备吗', '温馨提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				ctx.$request_.post(ctx.$api_.state.Carema.device
					.del.url, {
						e_id: e_id
					}).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})

						loadList()
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}).catch(() => {
				ctx.$notify({
					title: '温馨提示',
					message: '已取消',
					type: 'info'
				})
			})
	}

	// 同步设备下通道
	const synchChannels = (row) => {
		ctx.$confirm('您确认要同步该设备信息通道吗', '温馨提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				ctx.$request_.post(ctx.$api_.state.Carema.channels
					.synch.url, {
						e_id: row.param_id
					}).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})

						table_ref.value.store.states.treeData.value[row.nid].loaded = false
						table_ref.value.store.states.treeData.value[row.nid].expanded = false
						table_ref.value.store.loadOrToggle(row)
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}).catch(() => {
				ctx.$notify({
					title: '温馨提示',
					message: '已取消',
					type: 'info'
				})
			})
	}

	// 分页-选中页
	const currentChange = (page) => {
		table.page = page
		loadList()
	}

	// 分页-数量改变
	const sizeChange = (num) => {
		table.size = num
		loadList()
	}

	// 修改列表数据
	const setText = (scope, field, label, type = 'text') => {
		ctx.$prompt('请输入' + label, '您正在修改' + label, {
			confirmButtonText: '保存',
			cancelButtonText: '放弃修改',
			showClose: false,
			closeOnClickModal: false,
			inputPattern: /\S/,
			inputValue: scope['row'][field],
			inputType: type,
			inputErrorMessage: label + '格式不正确'
		}).then(({
			value
		}) => {
			if (scope.row.level === 1) {
				// 修改设备分组信息
				ctx.$request_.post(ctx.$api_.state.Carema.group
					.edit.url, {
						gr_id: scope.row.param_id,
						field: field,
						value: value
					}).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})

						scope['row'][field] = value
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			} else if (scope.row.level === 2) {
				// 修改设备信息
				ctx.$request_.post(ctx.$api_.state.Carema.device
					.edit.url, {
						e_id: scope.row.param_id,
						field: field,
						value: value
					}).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})

						scope['row'][field] = value
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}
		}).catch(() => {
			ctx.$notify({
				title: '温馨提示',
				message: '您放弃了修改' + label,
				type: 'info'
			})
		})
	}

	// 挂载
	const table_ref = ref(null)
	const channels_perview_ref = ref(null)
	const add_group_ref = ref(null)
	const add_device_ref = ref(null)

	// 打开视频预览界面
const openChannelsPerviewPage = (cs_id) => {
		channels_perview_ref.value.openPage(cs_id)
	}

	// 打开添加分组界面
	const openAddGroupPage = () => {
		add_group_ref.value.openPage()
	}

	// 打开添加设备界面
	const openAddDevicePage = (gr_id, gr_name) => {
		add_device_ref.value.openPage(gr_id, gr_name)
	}

	onMounted(() => {
		// 初始化加载区域列表
		ctx.$pubFun_.getSelectCommonBigarea(filter.bigarea)

		// 初始化加载设备分组列表
		ctx.$pubFun_.getSelectCommonGroup(filter.group, 0)

		// 初始化加载表格列表
		loadList()
	})
</script>
